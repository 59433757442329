















import Vue from 'vue'

//this is dummy component because redirect in router doesn't support to sent parameters
export default Vue.extend({
	name: 'Error404',
})
